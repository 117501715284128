import React from "react";
import { Layout } from "../../components";
import { CareersApplyForm } from "../../iframes";

export default function CareersApply(): React.ReactElement {
  return (
    <Layout transparentHeader={false}>
      <CareersApplyForm />
    </Layout>
  );
}
