import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Container } from "@italymondo/core-ui";

export const CareersApplyContainer = styled(Container)`
  ${({ theme }): SerializedStyles => css`
    margin: ${theme.spacing(10, 0)};
  `};
`;

export const LoadingSpinner = styled("div")`
  ${({ theme }): SerializedStyles => css`
    ${theme.cssMixins.rowHCentered};
    padding-top: ${theme.spacing(4)};
    margin: 0 auto;
    width: 100%;
  `}
`;
