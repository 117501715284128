import React from "react";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import { CircularProgress } from "@mui/material";
import { CareersApplyContainer, LoadingSpinner } from "./styles";

export function CareersApplyForm(): React.ReactElement {
  const { formCreated, loaded } = useHubspotForm({
    formId: "41366eb8-d20b-4db5-99aa-8fd8da0319d7",
    portalId: "6835722",
    target: "#careers-apply-form",
  });

  return (
    <CareersApplyContainer>
      {loaded && formCreated ? (
        <div id="careers-apply-form" />
      ) : (
        <LoadingSpinner>
          <CircularProgress
            aria-label="careers-apply-form-loading"
            color="primary"
          />
        </LoadingSpinner>
      )}
    </CareersApplyContainer>
  );
}
